import React from 'react'
import logo from './Images/HomeRest/logo.jpeg'
import { Button, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import servicesPageBg from './Images/RestImages/servicesPageBg.jpg'
import webDevelopment from './Images/HomeRest/webD.jpg'

import Mobiledevelopment from './Images/HomeRest/mobApp.jpg'
import uiuxDesign from './Images/HomeRest/uiuxDesign.jpg'
import aiIntegration from './Images/HomeRest/aiSul.jpg'
import graphics from './Images/HomeRest/graphics.jpg'

import './Styles/Services.css'
const Services = () => {
    return (
        <div className='text-light whole-div'
            style={{
                backgroundImage: `url(${servicesPageBg})`,
                backgroundAttachment: 'fixed', 
                backgroundSize: 'cover', 
                backgroundPosition: 'center', 
                backgroundRepeat: 'no-repeat', 
                backgroundColor: 'black', 
                // minHeight: '100vh',
                width: '100%',
                height: '100%'
            }}
        >
            <div className='content'>
                <section className='contentSection d-flex justify-content-center align-items-center text-align-center'
                    style={
                        {
                            height: '40vh'
                        }
                    }
                >
                    <h2 className='text-center'>Services</h2>
                </section>
                <section id='techs' className='contentSection'
                    style={{
                        boxShadow: 'none'
                    }}
                >
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-8 col-md-8 col-sm-12'
                                data-aos="fade-right" data-aos-delay='300'
                            >
                                <div className='outer-div'>
                                    <div className='d-flex justify-content-center inner-div'>

                                        <img
                                            className='rounded custom-image'
                                            alt='technologies'
                                            src={webDevelopment} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-4 col-sm-12 d-flex align-items-center'
                                data-aos="fade-left" data-aos-delay='300'
                            >
                                <div className='custom-text'>
                                    <h2 className=''>Technologies</h2>
                                    <p>We focus on providing the right tools for product innovation and digital communication.
                                    </p>

                                    <ul className=''>
                                        <li>Web and App Design</li>
                                        <li>Web Development</li>
                                        <li>Mobile Apps</li>
                                        <li>UI/UX Design</li>
                                        <li>Graphics Design</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*     Web Development */}
                <section id='web' className='contentSection pt-5 pb-5'
                    style={{
                        boxShadow: 'none'
                    }}
                >
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-4 col-md-4 col-sm-12 order-md-1 order-2 d-flex align-items-center'
                                data-aos="fade-down-right" data-aos-delay='300'
                            >
                                <div className='custom-text'>
                                    <h2>Web Development</h2>
                                    <p>We are dedicated to providing powerful web development tools to create dynamic and efficient websites that boost user interaction and growth. Our goal is to use the latest technology and smart design to build smooth and user-friendly online experiences.
                                    </p>
                                </div>
                            </div>
                            <div className='col-lg-8 col-md-8 col-sm-12 order-md-2 order-1'
                                data-aos="fade-left" data-aos-delay='300'
                            >
                                <div className='outer-div'>
                                    <div className='d-flex justify-content-center align-items-center inner-div'>
                                        <img
                                            className='rounded custom-image'
                                            alt='web-development'
                                            src={webDevelopment} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* ///   Mobile development services */}
                <section id='mobile' className='contentSection pt-5 pb-5'
                    style={{
                        boxShadow: 'none'
                    }}
                >
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-8 col-md-8 col-sm-12'
                                data-aos="fade-right" data-aos-delay='300'
                            ><div className='outer-div'>
                                    <div className='d-flex justify-content-center inner-div'>
                                        <img
                                            className='rounded custom-image'
                                            alt='Mobile-development'
                                            src={Mobiledevelopment} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-4 col-sm-12 d-flex align-items-center'
                                data-aos="fade-left" data-aos-delay='300'
                            >
                                <div className='custom-text'>
                                    <h2 className=''>Mobile Development</h2>
                                    <p>We are dedicated to providing powerful tools for mobile app development, focused on creating dynamic, high-quality apps that boost user engagement and growth. We prioritize using the latest technology and smart design to build smooth, user-friendly mobile experiences.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*     Ui Ux Design Development */}
                <section id='uiux' className='pt-5 pb-5'
                    style={{
                        boxShadow: 'none'
                    }}
                >
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-4 col-md-4 col-sm-12 order-md-1 order-2 d-flex align-items-center'
                                data-aos="fade-down-right" data-aos-delay='300'
                            >
                                <div className='custom-text'>
                                    <h2>Ui Ux Design</h2>
                                    <p>We are dedicated to offering effective tools for UI/UX design, focused on creating engaging, high-quality interfaces that enhance user interaction and satisfaction. Our emphasis is on using the latest design trends and intuitive layouts to create smooth, user-friendly experiences.</p>
                                </div>
                            </div>
                            <div className='col-lg-8 col-md-8 col-sm-12 order-md-2 order-1'
                                data-aos="fade-left" data-aos-delay='300'
                            >
                                <div className='outer-div'>

                                    <div className='d-flex justify-content-center align-items-center inner-div'>
                                        <img
                                            className='rounded custom-image'
                                            alt='Ui-ux-design'
                                            src={uiuxDesign} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Ai Solution services */}
                <section id='ai' className='pt-5 pb-5'
                    style={{
                        boxShadow: 'none'
                    }}
                >
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-8 col-md-8 col-sm-12'
                                data-aos="fade-right" data-aos-delay='300'
                            ><div className='outer-div'>
                                    <div className='d-flex justify-content-center inner-div'>
                                        <img
                                            className='rounded custom-image'
                                            alt='ai - solutions'
                                            src={aiIntegration} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-4 col-sm-12 d-flex align-items-center'
                                data-aos="fade-left" data-aos-delay='300'
                            >
                                <div className='custom-text'>
                                    <h2 className=''>AI Solutions</h2>
                                    <p>We are dedicated to providing powerful AI solutions, focused on developing intelligent, high-performance systems that enhance user engagement and business growth. Our focus is on utilizing advanced technology and smart algorithms to create seamless, user-friendly AI-driven experiences.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*     Graphics Design */}
                <section id='graphics' className='pt-5 pb-5'
                    style={{
                        boxShadow: 'none'
                    }}
                >
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-4 col-md-4 col-sm-12 order-md-1 order-2 d-flex align-items-center'
                                data-aos="fade-down-right" data-aos-delay='300'
                            >
                                <div className='custom-text'>
                                    <h2>Graphics Designing</h2>
                                    <p>We are committed to delivering effective tools for graphic design, focused on creating visually appealing, high-quality designs that captivate audiences and promote growth. Our emphasis is on using modern techniques and creative design to build seamless, user-friendly visual experiences.</p>
                                </div>
                            </div>
                            <div className='col-lg-8 col-md-8 col-sm-12 order-md-2 order-1'
                                data-aos="fade-left" data-aos-delay='300'
                            >
                                <div className='outer-div'>

                                    <div className='d-flex justify-content-center align-items-center inner-div'>
                                        <img
                                            className='rounded custom-image'
                                            alt='graphics-designing'
                                            src={graphics} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>

    )
}

export default Services
