import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import './Projectdetails.css'
import projects from './Projects.json'

// mobile app jaiza
import JaizaMobileHome4 from './ProjectImages/Mobile/JaizaMobileHome4.jpeg'
import JaizaMobileProfile7 from './ProjectImages/Mobile/JaizaMobileProfile7.jpeg'
import JaizaMobileLogin2 from './ProjectImages/Mobile/JaizaMobileLogin2.jpeg'
import JaizaMobileChats5 from './ProjectImages/Mobile/JaizaMobileChats5.jpeg'
import JaizaMobileServices6 from './ProjectImages/Mobile/JaizaMobileServices6.jpeg'
import JaizaMobileSignUp3 from './ProjectImages/Mobile/JaizaMobileSignUp3.jpeg'

// first app 1 
import firstapp1 from './ProjectImages/Mobile/firstapp1.jpg'
import firstapp2 from './ProjectImages/Mobile/firstapp2.jpg'
import firstapp3 from './ProjectImages/Mobile/firstapp3.jpg'
import firstapp4 from './ProjectImages/Mobile/firstapp4.jpg'
// first app 1 
import secondapp1 from './ProjectImages/Mobile/secondapp1.jpg'
import secondapp2 from './ProjectImages/Mobile/secondapp2.jpg'
import secondapp3 from './ProjectImages/Mobile/secondapp3.jpg'
import secondapp4 from './ProjectImages/Mobile/secondapp4.jpg'
// third app 3
import thirdapp1 from './ProjectImages/Mobile/thirdapp1.jpg'
import thirdapp2 from './ProjectImages/Mobile/thirdapp2.jpg'
import thirdapp3 from './ProjectImages/Mobile/thirdapp3.jpg'
import thirdapp4 from './ProjectImages/Mobile/thirdapp4.jpg'
// fourth app 3
import fourthapp1 from './ProjectImages/Mobile/fourthapp1.jpg'
import fourthapp2 from './ProjectImages/Mobile/fourthapp2.jpg'
import fourthapp3 from './ProjectImages/Mobile/fourthapp3.jpg'
import fourthapp4 from './ProjectImages/Mobile/fourthapp4.jpg'
import fourthapp5 from './ProjectImages/Mobile/fourthapp5.jpg'
// fifth app 3
import fifthapp1 from './ProjectImages/Mobile/fifthapp1.jpg'
import fifthapp2 from './ProjectImages/Mobile/fifthapp2.jpg'
import fifthapp3 from './ProjectImages/Mobile/fifthapp3.jpg'
import fifthapp4 from './ProjectImages/Mobile/fifthapp4.jpg'
// web app 1
import webproject1 from './ProjectImages/WebProjects/webproject1.png'
import webproject2 from './ProjectImages/WebProjects/webproject2.png'
import webproject3 from './ProjectImages/WebProjects/webproject3.png'
import webproject4 from './ProjectImages/WebProjects/webproject4.png'
// web app 2
import websecond1 from './ProjectImages/WebProjects/websecond1.png'
import websecond2 from './ProjectImages/WebProjects/websecond2.png'
import websecond3 from './ProjectImages/WebProjects/websecond3.png'
import websecond4 from './ProjectImages/WebProjects/websecond4.png'
import websecond5 from './ProjectImages/WebProjects/websecond5.png'

// ui ux design 

import projectManagCover1 from './ProjectImages/UiUxImages/projectManagCover1.jpg'
import projectManagHome2 from './ProjectImages/UiUxImages/projectManagHome2.png'
import projectManagTimeline3 from './ProjectImages/UiUxImages/projectManagTimeline3.png'
import projectManagFull4 from './ProjectImages/UiUxImages/projectManagFull4.jpg'

import bookStoreCover3 from './ProjectImages/UiUxImages/bookStoreCover3.jpg'
import bookStoreHome1 from './ProjectImages/UiUxImages/bookStoreHome1.jpg'
import bookStoreFull2 from './ProjectImages/UiUxImages/bookStoreFull2.jpg'
import bookStoreFull4 from './ProjectImages/UiUxImages/bookStoreFull4.jpg'
import bookStoreFull5 from './ProjectImages/UiUxImages/bookStoreFull5.jpg'

import homeRentCover1 from './ProjectImages/UiUxImages/homeRentCover1.jpg'
import homeRentHome2 from './ProjectImages/UiUxImages/homeRentHome2.jpeg'
import homeRent3 from './ProjectImages/UiUxImages/homeRent3.jpg'
import homeRent4 from './ProjectImages/UiUxImages/homeRent4.jpeg'
import homeRent5 from './ProjectImages/UiUxImages/homeRent5.jpeg'

import podcastCover1 from './ProjectImages/UiUxImages/podcastCover1.jpg'
import podcastHome2 from './ProjectImages/UiUxImages/podcastHome2.jpg'
import podcast3 from './ProjectImages/UiUxImages/podcast3.png'
import podcast4 from './ProjectImages/UiUxImages/podcast4.jpg'
import podcast5 from './ProjectImages/UiUxImages/podcast5.png'
import podcast6 from './ProjectImages/UiUxImages/podcast6.png'
import podcast7 from './ProjectImages/UiUxImages/podcast7.png'

import uberCover1 from './ProjectImages/UiUxImages/uberCover1.jpg'
import uberHome2 from './ProjectImages/UiUxImages/uberHome2.jpg'
import uber3 from './ProjectImages/UiUxImages/uber3.jpg'
import uber4 from './ProjectImages/UiUxImages/uber4.jpg'

import clockappCover1 from './ProjectImages/UiUxImages/clockappCover1.png'
import clockappHome2 from './ProjectImages/UiUxImages/clockappHome2.png'
import clockapp3 from './ProjectImages/UiUxImages/clockapp3.png'
import clockapp4 from './ProjectImages/UiUxImages/clockapp4.png'
import clockapp5 from './ProjectImages/UiUxImages/clockapp5.png'

import ebookCover1 from './ProjectImages/UiUxImages/ebookCover1.jpg'
import ebookHome2 from './ProjectImages/UiUxImages/ebookHome2.jpg'
import ebook3 from './ProjectImages/UiUxImages/ebook3.jpg'
import ebook4 from './ProjectImages/UiUxImages/ebook4.jpg'
import ebook5 from './ProjectImages/UiUxImages/ebook5.jpg'

import foodEcommerceHome2 from './ProjectImages/UiUxImages/foodEcommerceHome2.jpg'
import foodEcommerce3 from './ProjectImages/UiUxImages/foodEcommerce3.jpg'
import foodEcommerce4 from './ProjectImages/UiUxImages/foodEcommerce4.jpg'
import foodEcommerce5 from './ProjectImages/UiUxImages/foodEcommerce5.jpg'
import foodEcommerce6 from './ProjectImages/UiUxImages/foodEcommerce6.png'
import foodEcommerce7 from './ProjectImages/UiUxImages/foodEcommerce7.png'

import clinicHome2 from './ProjectImages/UiUxImages/clinicHome2.jpg'
import clinic3 from './ProjectImages/UiUxImages/clinic3.jpg'
import clinic4 from './ProjectImages/UiUxImages/clinic4.jpg'
import clinic5 from './ProjectImages/UiUxImages/clinic5.jpg'
import clinic6 from './ProjectImages/UiUxImages/clinic5.jpg'

import { Card } from 'react-bootstrap'

const Projectdetails = () => {
    const navigate = useNavigate()
    const { id } = useParams();
    const project = projects.find((p) => p.id === parseInt(id))

    const imageMap = {
        // jaiza app
        'JaizaMobileHome4': JaizaMobileHome4,
        'JaizaMobileProfile7': JaizaMobileProfile7,
        'JaizaMobileLogin2': JaizaMobileLogin2,
        'JaizaMobileChats5': JaizaMobileChats5,
        'JaizaMobileServices6': JaizaMobileServices6,
        'JaizaMobileSignUp3': JaizaMobileSignUp3,
        // first app 
        'firstapp1': firstapp1,
        'firstapp2': firstapp2,
        'firstapp3': firstapp3,
        'firstapp4': firstapp4,
        // 
        'secondapp1': secondapp1,
        'secondapp2': secondapp2,
        'secondapp3': secondapp3,
        'secondapp4': secondapp4,
        // 
        'thirdapp1': thirdapp1,
        'thirdapp2': thirdapp2,
        'thirdapp3': thirdapp3,
        'thirdapp4': thirdapp4,
        // 
        'fourthapp1': fourthapp1,
        'fourthapp2': fourthapp2,
        'fourthapp3': fourthapp3,
        'fourthapp4': fourthapp4,
        'fourthapp5': fourthapp5,
        //
        'fifthapp1': fifthapp1,
        'fifthapp2': fifthapp2,
        'fifthapp3': fifthapp3,
        'fifthapp4': fifthapp4,
// web 
'webproject1': webproject1,
'webproject2': webproject2,
'webproject3': webproject3,
'webproject4': webproject4,
//
'websecond1': websecond1,
'websecond2': websecond2,
'websecond3': websecond3,
'websecond4': websecond4,
'websecond5': websecond5,

// uiux design
'projectManagCover1': projectManagCover1,
'projectManagHome2': projectManagHome2,
'projectManagTimeline3': projectManagTimeline3,
'projectManagFull4': projectManagFull4,

'bookStoreCover3': bookStoreCover3,
'bookStoreHome1': bookStoreHome1,
'bookStoreFull2': bookStoreFull2,
'bookStoreFull4': bookStoreFull4,
'bookStoreFull5': bookStoreFull5,

'homeRentCover1': homeRentCover1,
'homeRentHome2': homeRentHome2,
'homeRent3': homeRent3,
'homeRent4': homeRent4,
'homeRent5': homeRent5,

'podcastCover1': podcastCover1,
'podcastHome2': podcastHome2,
'podcast3': podcast3,
'podcast4': podcast4,
'podcast5': podcast5,
'podcast6': podcast6,
'podcast7': podcast7,

'uberCover1': uberCover1,
'uberHome2': uberHome2,
'uber3': uber3,
'uber4': uber4,


'clockappCover1': clockappCover1,
'clockappHome2': clockappHome2,
'clockapp3': clockapp3,
'clockapp4': clockapp4,
'clockapp5': clockapp5,

'ebookCover1': ebookCover1,
'ebookHome2': ebookHome2,
'ebook3': ebook3,
'ebook4': ebook4,
'ebook5': ebook5,


'foodEcommerceHome2': foodEcommerceHome2,
'foodEcommerce3': foodEcommerce3,
'foodEcommerce4': foodEcommerce4,
'foodEcommerce5': foodEcommerce5,
'foodEcommerce6': foodEcommerce6,
'foodEcommerce7': foodEcommerce7,

'clinicHome2': clinicHome2,
'clinic3': clinic3,
'clinic4': clinic4,
'clinic5': clinic5,
'clinic6': clinic6,

    }

    if (!project) {
        return <p className='text-dark'>Project not Found</p>
    }
    return (
        <div className='wholeDivProjectDetails text-light pb-5'>
            <section className='headerSection d-flex justify-content-center align-items-center text-align-center'>
            </section>
           
            <section className='p-3'>
                <button 
                style={{
                    fontWeight: '900',
                    fontSize: '20px'
                }}
                className='btn text-light m-3' onClick={() => navigate(-1)}><i className="fs-4 bi bi-arrow-left"></i></button>
                <div className='container'>

                    <h2 className=''>{project.title}</h2>
                    <p>{project.description}</p>
                    <div className='row'>
                        <h3 className='mb-3 mt-5 p-3'>Features</h3>
                        <div className='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center align-items-center'>
                            <img className='HomeScreenImageUrl rounded' src={imageMap[project.HomeScreenImageUrl]} alt='Home Screen'/>
                        </div>
                        <div className='col-lg-8 col-md-6 col-sm-12 d-flex justify-content-center align-items-center'>
                            <ul>
                                {
                                    project.features.map((feat, index) => (
                                        <li key={index}>{feat}</li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div className='row'>
                        <h3 className='mb-3 mt-5 p-3'>Technology Stack</h3>

                        {
                            project.technologies.map((tech, index) => (
                                <div className='col-lg-4 col-md-4 col-sm-12'>
                                    <Card className='m-3'>
                                        <Card.Body>
                                    <p className='p-3' key={index}>{tech}</p>
                                        </Card.Body>
                                    </Card>
                                </div>
                            ))
                        }

                    </div>
                    <div className='row m-3 d-flex justify-content-center align-items-center'>
                        {
                            project.mockups.map((mockups, index) => (
                                <div className='mb-3 col-lg-3 col-md-5 col-sm-12 d-flex justify-content-center align-items-center' key={index}>
                                    <img className='mockup-images rounded' src={imageMap[mockups.imageUrl]} alt={mockups.title} />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Projectdetails
