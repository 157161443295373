import React from 'react'
import video from '../Pages/Images/HomeBackground/video.mp4'
// import Video3 from '../Pages/Images/HomeBackground/Video3.mp4'
import './Styles/Home.css'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import AboutSection from './Home/AboutSection'
import Servicescection from './Home/Servicescection'


import Whychooseus from './Home/Whychooseus'
import Milestones from './Home/Milestones'
import Whatpeoplesay from './Home/Whatpeoplesay'
const Home = () => {
    const navigate = useNavigate()
    return (
        <div>
            <section className='main-section d-flex align-items-center justify-content-center'>
                {/* <video loading="lazy" className='background-video video1' autoPlay muted loop>
                    <source src={Video3} type='video/mp4' />
                </video> */}
                <video loading="lazy" className='background-video video2' autoPlay muted loop>
                    <source src={video} type='video/mp4' />
                </video>
                    <div className='col-lg-8 col-md-10 col-sm-12 content'>
                        <h1 className='mainSectionHeading mt-4'>Join us to Revolutionalize,<br /> The Digital Landscape.</h1>
                        <p className='mainSectionPara'>We would convert your conventional business into Digital and automated business by using latest cutting edge tech stacks, an end-to-end web based apps development life cycle to meet each business’ needs.</p>
                        <div className='text-center'>
                            <Button onClick={(e) => {navigate('/about')}} className='btn mt-3' variant='outline-light'> Get Started</Button>
                        </div>
                </div>


            </section>
            {/* /////   2nd milestones    /////////////// */}

            {/* /////   3rd Services Section/////////////// */}
            {/* /////   4th About Section/////////////// */}
            {/* /////   5th why choose us Section/////////////// */}
            {/* /////   6th what people say about US/////////////// */}
            {/* Footer */}
            <Milestones />
            <AboutSection />
            <Servicescection />
            <Whychooseus />
            <Whatpeoplesay />
            {/* <Contactsection /> */}


        </div >
    )
}

export default Home
