import React from 'react'
import './Servicessection.css'
import { Link } from 'react-router-dom'
import { Card, Button } from 'react-bootstrap'
import webDevelopment from '../Images/HomeRest/webD.jpg'
import Mobiledevelopment from '../Images/HomeRest/mobApp.jpg'
import uiuxDesign from '../Images/HomeRest/uiuxDesign.jpg'
import aiIntegration from '../Images/HomeRest/aiSul.jpg'
import graphics from '../Images/HomeRest/graphics.jpg'

import HomeServicesSectionBg from '../Images/RestImages/HomeServicesSectionBg.jpg'

import { useEffect } from 'react'


const Servicescection = () => {

    return (
        <section className='p-3 pb-5 services'
            style={{
                backgroundImage: `url(${HomeServicesSectionBg})`,
                width: '100%',
                height: '100%',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}
        >
            <h2 className='text-center'>Services We Offer </h2>
            <div className='container text-center'>
                <div className='row d-flex justify-content-center'>
                    <div className='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center cardscolDiv mb-5'>
                        <Link className='text-decoration-none' to="/services">
                            <Card className='cardscolDiv'
                                data-aos="fade-up" data-aos-delay="200">
                                <Card.Img className='serviceCardsImg' variant="top" src={webDevelopment} />
                                <Card.Body>
                                    <Card.Title>Web Development</Card.Title>
                                </Card.Body>
                            </Card>
                        </Link>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center cardscolDiv mb-5'>
                    <Link className='text-decoration-none' to='/services#mobile'>
                        <Card className='cardscolDiv'
                            data-aos='fade-up' data-aos-delay='200'>
                            <Card.Img className='serviceCardsImg' variant="top" src={Mobiledevelopment} />
                            <Card.Body>
                                <Card.Title>Mobile Development</Card.Title>
                            </Card.Body>
                        </Card>
                        </Link>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center cardscolDiv mb-5'>
                    <Link className='text-decoration-none' to='/services#uiux'>
                        <Card className='cardscolDiv'
                            data-aos='fade-up' data-aos-delay='200'>
                            <Card.Img className='serviceCardsImg' variant="top" src={uiuxDesign} />
                            <Card.Body>
                                <Card.Title>UI / UX Design</Card.Title>
                            </Card.Body>
                        </Card>
                        </Link>
                    </div>
                    <div className='row d-flex justify-content-center'>
                        <div className='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center cardscolDiv mb-5'>
                        <Link className='text-decoration-none' to='/services#ai'>
                            <Card className='cardscolDiv'
                                data-aos='fade-up' data-aos-delay='200'>
                                <Card.Img className='serviceCardsImg' variant="top" src={aiIntegration} />
                                <Card.Body>
                                    <Card.Title>AI Solutions</Card.Title>
                                </Card.Body>
                            </Card>
                            </Link>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center cardscolDiv mb-5'>
                        <Link className='text-decoration-none' to='/services#graphics'>
                            <Card className='cardscolDiv'
                                data-aos='fade-up' data-aos-delay='200'>
                                <Card.Img className='serviceCardsImg' variant="top" src={graphics} />
                                <Card.Body>
                                    <Card.Title>Graphics Design</Card.Title>
                                </Card.Body>
                            </Card>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Servicescection
