import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { UseNavigate } from 'react-router-dom'
import projects from './Portfoliochilds/Projects.json'
import './Styles/Portfolio.css'

import JaizaMobileOne1 from './Portfoliochilds/ProjectImages/Mobile/JaizaMobileOne1.jpeg'
import CoreReliefCover1 from './Portfoliochilds/ProjectImages/Mobile/CoreReliefCover1.jpeg'
import DoorstepCover2  from './Portfoliochilds/ProjectImages/Mobile/DoorstepCover2.jpeg'
import FankarFunkayCover3 from './Portfoliochilds/ProjectImages/Mobile/FankarFunkayCover3.jpeg'
import GMGCover4 from './Portfoliochilds/ProjectImages/Mobile/GMGCover4.jpeg'
import BloodPressureCover5 from './Portfoliochilds/ProjectImages/Mobile/BloodPressureCover5.jpeg'
import InventoSmartCover from './Portfoliochilds/ProjectImages/WebProjects/InventoSmartCover.jpeg'
import EcopanelMarketplaceCover from './Portfoliochilds/ProjectImages/WebProjects/EcopanelMarketplaceCover.jpeg'
import projectManagCover1 from './Portfoliochilds/ProjectImages/UiUxImages/projectManagCover1.jpg'
import bookStoreCover3 from './Portfoliochilds/ProjectImages/UiUxImages/bookStoreCover3.jpg'
import homeRentCover1 from './Portfoliochilds/ProjectImages/UiUxImages/homeRentCover1.jpg'
import podcastCover1 from './Portfoliochilds/ProjectImages/UiUxImages/podcastCover1.jpg'
import uberCover1 from './Portfoliochilds/ProjectImages/UiUxImages/uberCover1.jpg'
import clockappCover1 from './Portfoliochilds/ProjectImages/UiUxImages/clockappCover1.png'
import ebookCover1 from './Portfoliochilds/ProjectImages/UiUxImages/ebookCover1.jpg'
import foodEcommerceCover1 from './Portfoliochilds/ProjectImages/UiUxImages/foodEcommerceCover1.jpg'
import clinicCover1 from './Portfoliochilds/ProjectImages/UiUxImages/clinicCover1.jpg'
const Portfolio = () => {
  const [selectCategory, setSelectCategory] = useState('All')
  const navigate = useNavigate();

  const imageMap = {
    "JaizaMobileOne1": JaizaMobileOne1,
    "CoreReliefCover1": CoreReliefCover1,
    "DoorstepCover2": DoorstepCover2,
    "FankarFunkayCover3": FankarFunkayCover3,
    "GMGCover4": GMGCover4,
    "BloodPressureCover5": BloodPressureCover5,
    'InventoSmartCover': InventoSmartCover,
    'EcopanelMarketplaceCover': EcopanelMarketplaceCover,
    'projectManagCover1': projectManagCover1,
    'bookStoreCover3': bookStoreCover3,
    'homeRentCover1': homeRentCover1,
    'podcastCover1': podcastCover1,
    'uberCover1': uberCover1,
    'clockappCover1': clockappCover1,
    'ebookCover1': ebookCover1,
    'foodEcommerceCover1': foodEcommerceCover1,
    'clinicCover1': clinicCover1
  }
  const categories = ['All', 'Web Development', 'Mobile Development', 'UI/UX Design', 'AI Solutions'];
  const handleClick = (category) => {
    setSelectCategory(category)
    console.log(selectCategory)
  }
  const filterProject = selectCategory === 'All'
    ? projects
    : projects.filter(p => p.category === selectCategory)
  return (
    <div className='wholeContainerPortfolio text-light'>
      <section className='headerSection d-flex justify-content-center align-items-center text-align-center'>
      </section>
      <section className=' p-3'
        style={{ background: '' }}>
        <h2 className='text-center'>Discover Our Achievements</h2>
        <p className='text-center'>Empowering clients worldwide with cutting-edge technology solutions.</p>
        <div className='d-flex justify-content-center gap-5 mb-5'>
          {/* Category Buttons */}
          <div style={{ marginBottom: '20px' }}>
            {categories.map((category) => (
              <button
                key={category}
                onClick={() => handleClick(category)}
                style={{
                  margin: '0 10px',
                  padding:  '10px 20px',
                  backgroundColor: selectCategory === category ? 'purple' : 'transparent', // Highlighting
                  color: selectCategory === category ? 'white' : 'white',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
              >
                {category}
              </button>
            ))}</div>
        </div>
        <div className='container'>
          <div className='row'>
            {
              filterProject.length > 0 ? (
                filterProject.map((project) => (
                  <div className='col-lg-4 col-md-6 col-sm-12 p-4'
                    key={project.id}
                    onClick={() => navigate(`/project/${project.id}`)}
                  >
                    <h5>{project.title}</h5>
                    <img className='cover-images rounded' src={imageMap[project.CoverImageUrl]} alt={project.title} />
                  </div>
                ))
              ) : (
                <p style={{ textAlign: 'center', fontSize: '18px', color: 'gray' }}>No projects to show</p>
              )
            }
          </div>
        </div>
      </section>
    </div>
  )
}

export default Portfolio
