import React from 'react'
import './Whatpeoplesay.css'
import { useState, useEffect } from 'react'
import { Carousel, Row, Col, Card } from 'react-bootstrap'
const Whatpeoplesay = () => {
    const cardsData = [
        { name: 'Winnie Ng – Musio', position: 'client', rating: 'bi bi-star-fill', review: "Infinite Algos has been an incredible partner in bringing Musio to life. They listened, innovated, and brought forward ideas that matched my passion for connecting people through music. The app they built is user friendly, intuitive, vibrant, and truly engaging. Infinite Algos' expertise and commitment have made Musio a platform I’m proud of and it meant alot to me." },
        { name: 'Mukhtar Ahmed – GMG', position: 'client', rating: 'bi bi-star-fill', review: "I couldn’t have asked for a better partner than Infinite Algos to develop GMG. They transformed my ideas into a dynamic, versatile platform that supports growth and management seamlessly. Their dedication to quality and their understanding of my requirements have resulted in a product that exceeds expectations. Infinite Algos has played a vital role in our success." },
        { name: 'Jeffery Gainey – Property Market', position: 'client', rating: 'bi bi-star-fill', review: "Infinite Algos turned my vision for Property Market into a powerful, user-centric platform. Their team’s expertise in real estate technology helped create an intuitive tool that simplifies property transactions for everyone. Working with Infinite Algos has been an insightful experience, and their commitment to excellence shines through the platform." },
        { name: 'Ayman Humaida – Mind Relax', position: 'client', rating: 'bi bi-star-fill', review: "Working with Infinite Algos on the Mind Relax app has been nothing short of exceptional. Their team understood my vision for creating a peaceful, user-friendly platform, and they delivered a seamless app that truly resonates with users seeking tranquility. Infinite Algos has made this journey rewarding, and I couldn’t be happier with the result." },
        { name: 'Chen Cohen – Be-Pass', position: 'client', rating: 'bi bi-star-fill', review: "My experience with Infinite Algos in developing Be-Pass has been transformative. They prioritized user security and convenience, delivering a platform that’s as reliable as it is user-friendly. The professionalism, creativity, experience and forward-thinking approach of Infinite Algos have made this project successful and have greatly enhanced user trust in Be-Pass." },
        
    ]
    const [displayLength, setDisplayLength] = useState(3);

    useEffect(() => {
        const updateDisplayLength = () => {
            if (window.innerWidth < 576) {
                setDisplayLength(1); // 1 card for mobile screens
            } else if (window.innerWidth < 768) {
                setDisplayLength(2); // 2 cards for tablets
            } else {
                setDisplayLength(3); // 3 cards for laptops and desktops
            }
        };

        updateDisplayLength();
        window.addEventListener('resize', updateDisplayLength);

        return () => window.removeEventListener('resize', updateDisplayLength);
    }, []);

    const cardsDisplay = []
    for (let i = 0; i < cardsData.length; i += displayLength) {
        cardsDisplay.push(cardsData.slice(i, i + displayLength))
    }
    const displaySize = 3;
    const displayCards = []
    for (let i = 0; i < cardsData.length; i += displaySize) {
        displayCards.push(cardsData.slice(i, i + displaySize))
        console.log("fkdkjd")
    }

    return (
        <div>
            <section className='p-2 m-1'>
                <h2 className='text-center text-dark'>What People say About Us</h2>
                <div className='container'>
                    <Carousel interval={3000} pause="hover"
                     prevIcon={<span className="carousel-control-prev-icon" style={{ backgroundColor: 'purple' }} />}
                     nextIcon={<span className="carousel-control-next-icon" style={{ backgroundColor: 'purple' }} />}
                    >
                        {cardsDisplay.map((c, index) => (
                            <Carousel.Item key={index}>
                                <Row className="justify-content-center">
                                    {c.map(card => (
                                        <Col xs={11} md={5} lg={3} key={card.id} className="mb-3">
                                            <Card style={{ width: '100%', margin: 'auto', padding: '10px' }}>
                                                {/* <Card.Img variant="top" src={card.image} /> */}
                                                <Card.Body className='text-center'>
                                                    <Card.Title>{card.name}</Card.Title>
                                                    <Card.Text className='text-center text-muted'>{card.position}</Card.Text>
                                                    <Card.Text className='text-center'><i className={`${card.rating} stars p-1`}></i><i className={`${card.rating} stars p-1`}></i><i className={`${card.rating} stars p-1`}></i><i className={`${card.rating} stars p-1`}></i><i className={`${card.rating} stars p-1`}></i></Card.Text>
                                                    <Card.Text>{card.review}</Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
            </section>
            
        </div>
    )
}

export default Whatpeoplesay
