import 'bootstrap/dist/css/bootstrap.css'; // bootstrap
import 'bootstrap-icons/font/bootstrap-icons.css'; // bootstrap icons
import { BrowserRouter as Router, Routes, Route, BrowserRouter } from 'react-router-dom'
import React from 'react'
import Home from '../Pages/Home';
import Services from '../Pages/Services';
import About from '../Pages/About';
import Contact from '../Pages/Contact';
import Error from '../Pages/Error'
import Headnavbar from './Header/Headnavbar';
import Hirring from '../Pages/Hirring';
import Team from '../Pages/Team';
import Directors from '../Pages/Directors';
import Footerbar from './Footer/Footerbar';
import AOS from 'aos'
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import Portfolio from '../Pages/Portfolio';
import Projectdetails from '../Pages/Portfoliochilds/Projectdetails';
import ScrollToTop from './ScrollToTop';

// import { Suspense, lazy } from 'react';
const App = () => {
{/* <Suspense fallback={<div>Loading About...</div>}></Suspense> */}


    useEffect(() => {
        setTimeout(() => {
        AOS.init({ duration: 1000 }); // Duration of the animation in milliseconds
        console.log('i am rendered')
    }, 500)
    }, []);

    return (
        <div>
            <Router>
                <Headnavbar />
                <ScrollToTop />
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/home' element={<Home />} />
                    <Route path='/services' element={<Services />} />
                    <Route path='/about' element={<About />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/portfolio' element={<Portfolio />} />
                    <Route path='/project/:id' element={<Projectdetails />} />
                    <Route path='/hirring' element={<Hirring />} />
                    <Route path='/team' element={<Team />} />
                    <Route path='*' element={<Error />} />
                </Routes>
                <Footerbar />
            </Router>
        </div>
    )
}

export default App
