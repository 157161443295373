import React from 'react'
import { Card, Carousel, Row, Col } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import webIcon from './Images/HomeRest/webIcon.png'
import '../Pages/Styles/Team.css'

import LifeAt from './Aboutchilds/LifeAt.jpeg'

import teambg from './Images/RestImages/teambg.jpg'
// Team images
import HamzaTariq from './Aboutchilds/TeamImages/HamzaTariq.png'
import MAhmed from './Aboutchilds/TeamImages/MAhmed.png'
import SomamaAyan from './Aboutchilds/TeamImages/SomamaAyan.png'
import AyeshaAhmed from './Aboutchilds/TeamImages/AyeshaAhmed.png'
import QuratUlAin from './Aboutchilds/TeamImages/QuratUlAin.png'
import Sonam from './Aboutchilds/TeamImages/Sonam.png'

// Innovators images
import DrAbdulBaseerImage from './Aboutchilds/InnovatorsImages/DrAbdulBaseerImage.jpeg'
import JunaidNasir from './Aboutchilds/InnovatorsImages/JunaidNasir.png'
import MaryamAbad from './Aboutchilds/InnovatorsImages/MaryamAbad.png'
import Teamdata from './Aboutchilds/Teamdata.json'
import Innovatorsdata from './Aboutchilds/Innovatorsdata.json'
const Team = () => {
    const imageMap = {
        
        'profilePic': webIcon,
        'DrAbdulBaseerImage': DrAbdulBaseerImage,
        'MaryamAbad': MaryamAbad,
        'JunaidNasir': JunaidNasir,
        // Team map
        'HamzaTariq' :HamzaTariq,
        'QuratUlAin': QuratUlAin,
        'Sonam': Sonam,
        'MAhmed': MAhmed,
        'SomamaAyan': SomamaAyan,
        'AyeshaAhmed': AyeshaAhmed
    }
    const [displaySize, setDisplaySize] = useState()
    // const displaySize = 3;
    useEffect(() => {
        const updateDisplayLength = () => {
            if(window.innerWidth < 576){
                setDisplaySize(1)
            }else if(window.innerWidth < 768){
                setDisplaySize(2)
            }else{
                setDisplaySize(3)
            }
        }
        updateDisplayLength()
        window.addEventListener('resize', updateDisplayLength)

        return window.removeEventListener('resize', updateDisplayLength)
    }, [])
    const displayCards = []
    for (let i = 0; i < Teamdata.length; i += displaySize) {
        displayCards.push(Teamdata.slice(i, i + displaySize))
        console.log("fkdkjd")
    }
    return (
        <div>
            <section className='p-3 m-3'>
                <h2 className='text-center text-dark'>Meet Our Innovators</h2>
                <div className='container'>
                    <div className='row d-flex justify-content-center'>
                        <div className='col-lg-6 p-3'
                        data-aos="fade-down" data-aos-delay='300'
                        >
                            <div className='d-flex justify-content-center p-2'>
                                <img
                                    style={{
                                        width: "20rem",
                                        height: 'auto'
                                    }}
                                    src={imageMap[Innovatorsdata[0].profile]} alt='founder-image' />
                            </div>
                            <h4 className='text-center pt-2'>{Innovatorsdata[0].title}</h4>
                            <h6 className='text-center  text-muted'>{Innovatorsdata[0].position}</h6>
                            <p className='text-center m-2'>{Innovatorsdata[0].descText} </p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='d-flex justify-content-center p-2'
                            data-aos="fade-up" data-aos-delay='300'
                            >
                                <img
                                    style={{
                                        width: "20rem"
                                    }}
                                    src={imageMap[Innovatorsdata[1].profile]} alt='founder-image' />
                            </div>
                            <h4 className='text-center pt-2'>{Innovatorsdata[1].title}</h4>
                            <h6 className='text-center  text-muted'>{Innovatorsdata[1].position}</h6>
                            <p className='text-center m-2'>{Innovatorsdata[1].descText}</p>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12'
                        data-aos="fade-up" data-aos-delay='300'
                        >
                            <div className='d-flex justify-content-center p-2'>
                                <img
                                    style={{
                                        width: "20rem"
                                    }}
                                    src={imageMap[Innovatorsdata[2].profile]} alt='founder-image' />
                            </div>
                            <h4 className='text-center pt-2'>{Innovatorsdata[2].title}</h4>
                            <h6 className='text-center  text-muted'>{Innovatorsdata[2].position}</h6>
                            <p className='text-center m-2'>{Innovatorsdata[2].descText}</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className=''
                style={{
                    backgroundImage: `url(${teambg})`,
                    width: '100%',
                    height: '100%',
                    paddingTop: '6rem',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <h2 className='text-center text-dark'>Meet Our Talented Team</h2>
                <p className='text-center pb-5'>We are already a Family of 20+ Talented Individuals & Growing</p>
                <div className='container'>
                    <Carousel interval={2000} pause="hover"
                    prevIcon={<span className="carousel-control-prev-icon" style={{ backgroundColor: 'purple' }} />}
                    nextIcon={<span className="carousel-control-next-icon" style={{ backgroundColor: 'purple' }} />}
                    >
                        {displayCards.map((c, index) => (
                            <Carousel.Item key={index}>
                                <div className="row justify-content-center">
                                    {c.map(card => (
                                        <div key={card.id} className="col-lg-3 col-md-6 col-sm-12 mb-3 m-3">
                                            <Card className='TeamCards' style={{ width: '100%', margin: '10px' }}>
                                                <Card.Img className='TeamCardImage' variant="top" src={imageMap[card.img]} />
                                                <Card.Body style={{background: `${card.background}`}} className='cardBodyTeam'>
                                                    <Card.Title className='text-center'>{card.title}</Card.Title>
                                                    <Card.Text className='text-center'>{card.text}</Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    ))}
                                </div>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
            </section>

            {/*      /////    Life at Infinite ALgos        ////*/}
            <section className='pb-5'
            style={{
                backgroundImage: `url(${LifeAt})`,
                    width: '100%',
                    height: '100%',
                    paddingTop: '6rem',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
            }}
            >
                <div className='container'>
                <h4 className=''>Life at</h4>
                <h2 className='text-dark'>Infinite Algos</h2>
                    <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-lg-12'>
                            <p>At Infinite Alogos, we are dedicated to nurturing a collaborative and innovative space where creativity and growth go hand in hand. Founded by industry experts, we encourage a culture of continuous improvement, fresh ideas, and teamwork. Our startup is guided by values of honesty, quality, and a deep love for technology. From developing state-of-the-art software to exploring new possibilities, life at Infinite Alogos is about making a difference while enjoying the process together.</p>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    )
}

export default Team
