import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import aboutusPageNew from './Images/RestImages/aboutusPageNew.jpeg';
import Team from './Team';
import Directors from './Directors';
import './Styles/About.css';

const About = () => {
    const navigate = useNavigate();

    console.log("Component is rendered");

    const handleButtonClick = () => {
        console.log("Button clicked");
        navigate('/contact'); // Navigate to contact directly on click
    };

    return (
        <div>
            <section
                className="about-section d-flex justify-content-center text-align-center"
                style={{
                    backgroundImage: `url(${aboutusPageNew})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: 'black',
                    width: '100%',
                    height: 'auto',
                    objectFit: 'cover'
                }}
            >
                <div className="col-lg-10 col-md-12 col-sm-12 justify-text-center align-items-center">
                    <h2 className="mt-3 text-center text-light">About</h2>
                    <p className="p-3 text-light justify-text-center">
                        Welcome to INFINITE ALGOS, where creativity blends with innovation. We excel in advanced AI solutions, smooth mobile app development, engaging web experiences, and immersive UI/UX and graphic design. Our dedicated team is passionate about advancing technology to create digital experiences that inspire and empower.
                        <br /><br />
                        At INFINITE ALGOS, we do more than just software development; we build solutions that enhance business operations through AI-driven insights and provide memorable user experiences in UI/UX, mobile, web, and graphics design. Our committed team is focused on bringing your vision to life. Join us in transforming the future of digital solutions, one algorithm at a time.
                    </p>
                    <div className="text-center">
                        <Link to="/contact">
                            <Button className="btn mt-3" variant="outline-light">Contact Us</Button>
                        </Link>
                    </div>
                </div>

                {/* SVG Wave at the Bottom */}
                <svg viewBox="0 0 1440 320" className="svg-wave">
                    <path
                        fill="#fff"
                        fillOpacity="1"
                        d="M0,224L60,197.3C120,171,240,117,360,128C480,139,600,213,720,240C840,267,960,245,1080,234.7C1200,224,1320,224,1380,224L1440,224L1440,320L0,320Z"
                    ></path>
                </svg>
            </section>

            {/* This button navigates directly to contact */}

            <Team />
            <Directors />
        </div>
    );
};

export default About;
