import React from 'react'
import { Button } from 'react-bootstrap'
import './Styles/Hirring.css'
const Hirring = () => {
    return (
        <div>

<section className='contentSection d-flex justify-content-center align-items-center text-align-center'
                style={
                    {
                        height:'20vh',
                        background: 'black'
                    }
                }
                >
                </section>
        <section className='p-3 m-3'
            style={{
                textAlign: 'justify'
            }}
        >
            <div className='container hirringDivContainer'>
            <h2 className='text-center'>Join Our Team at INFINITE ALGOS!</h2>
                <div className='row hirringDivRow'>
                    <div className='col-lg-6 col-md-6 col-md-12 hirringDivCol'>
                        <h4 className='text-center pb-2'>What We’re Looking For</h4>
                        <ul>
                            <li>Flutter Developers: Build seamless, high-performance mobile apps.</li>
                            <li>Unity Developers: Create engaging and immersive gaming experiences. </li>
                            <li>React Developers: Develop dynamic and responsive web applications.  </li>
                            <li>Machine Learning Experts: Implement intelligent algorithms to drive data insights.</li>
                        </ul>
                    </div>
                    <div className='col-lg-6 col-md-6 col-md-12 hirringDivCol'>
                        <h4 className='text-center pb-2'>Why work with us</h4>
                        <ul>
                            <li>Innovative Projects: Work on cutting-edge technology and impactful solutions.</li>
                            <li>Dynamic Team: Collaborate with a team of forward-thinking professionals.</li>
                            <li>Growth Opportunities: Enhance your skills and advance your career.</li>
                            <li>Creative Environment: Contribute to projects that challenge and inspire.</li>
                        </ul>
                    </div>
                    <div className='col-md-12 text-center m-3'>
                    <Button variant='dark'>Contact US</Button>
                    </div>
                </div>
            </div>
        </section>
                </div>
    )
}

export default Hirring
