import React from 'react'
import './Whychooseus.css'
import whyCUBackground from '../Images/RestImages/whyCUBackground.jpg'
const Whychooseus = () => {
    return (
        <section className='p-3 pb-5 mb-3 text-light'
        style={{
            backgroundImage: `url(${whyCUBackground})`,
            height: 'auto',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',


            backgroundColor: 'black'
        }}
        >
            <h4 className='text-center p-3'>Why Choose Us</h4>
            <h3 className='text-center m-2'>People choose us because<br/> We serve the best for everyone</h3>
            <div className='container'>
                <div className='row d-flex justify-content-center '>
                    <div className='col-lg-5 col-md-5 col-sm-12 p-4 whyChooseUsCol m-2'
                    data-aos="fade-right" data-aos-delay="300"
                    >
                        <h5>
                            <i className="bi bi-people m-2"></i>
                            Project Managers
                        </h5>
                        <p>Our dedicated project managers ensure that every aspect of your project is meticulously planned and executed. By leveraging the latest tools and methodologies.</p>
                    </div>
                    <div className='col-lg-5 col-md-5 col-sm-12 p-4 whyChooseUsCol m-2'
                    data-aos = 'fade-left' data-aos-delay ='300'
                    >
                        <h5>
                            <i className="bi bi-bookshelf m-2"></i>
                            Organized Tasks
                        </h5>
                        <p>We utilize advanced task management systems to categorize and prioritize workload effectively. This organization allows team to focus on critical tasks.</p>

                    </div>
                </div>
                <div className='row d-flex justify-content-center'>
                    <div className='col-lg-5 col-md-5 col-sm-12 p-4 whyChooseUsCol m-2'
                    data-aos = 'fade-right' data-aos-delay='300'
                    >
                        <h5>
                            <i className="bi bi-chat m-2"></i>
                            Feedback Sharing
                        </h5>
                        <p>We encourage open communication and collaboration through our feedback-sharing platforms. This fosters a culture of continuous improvement, enabling us to adapt and enhance our solutions based on your insights.</p>
                    </div>
                    <div className='col-lg-5 col-md-5 col-sm-12 p-4 whyChooseUsCol m-2'
                    data-aos='fade-left' data-aos-delay ='300'
                    >
                        <h5>
                            <i className="bi bi-stopwatch m-2"></i>
                            Never Miss Deadline
                        </h5>
                        <p>Our proactive approach and real-time tracking systems guarantee that deadlines are met consistently. We monitor progress closely and address any potential roadblocks.</p>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Whychooseus
