import React from 'react'
import teambg from './Images/RestImages/teambg.jpg'
import { Card, Carousel, Row, Col } from 'react-bootstrap'
import webIcon from './Images/HomeRest/webIcon.png'
// import SomamaAyan from './Aboutchilds'
import Directorsdata from './Aboutchilds/Directorsdata.json'
import './Styles/Directors.css'

// lotie animation
import Lottie from 'lottie-react';
import animationData from './commingSoonLotie.json'
const Directors = () => {
    const imageMap = {
        'ahmadImg': webIcon
    }
    const displaySize = 3;
    const displayCards = []
    for (let i = 0; i < Directorsdata.length; i += displaySize) {
        displayCards.push(Directorsdata.slice(i, i + displaySize))
        console.log("fkdkjd")
    }
    return (
        <div>
            <section className=''
                style={{
                    backgroundImage: `url(${teambg})`,
                    width: '100%',
                    height: '100%',
                    paddingTop: '6rem',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <h2 className='text-center text-dark'>Meet Our Board of Directors</h2>
                {/* <p className='text-center pb-5'>We are already a Family of 20+ Talented Individuals & Growing</p> */}

                <div className='d-flex justify-content-center'>
                    <Lottie animationData={animationData} loop={true} style={{ height: 300, width: 300 }} />
                </div>
                {/* <div className='container'>
                    <Carousel interval={2000} pause="hover">
                        {displayCards.map((c, index) => (
                            <Carousel.Item key={index}>
                                <div className="row justify-content-center">
                                    {c.map(card => (
                                        <div key={card.id} className="col-lg-3 col-md-6 col-sm-12 mb-3 m-3">
                                            <Card className='DirectorsCard' style={{ width: '100%', margin: '15px' }}>
                                                <span style={{background: 'goldenrod'}} className='position-relative'>
                                                <i className="bi bi-shield-fill Shieldbadge"></i>
                                                </span>
                                                <Card.Img className='DirectorsCardImage' variant="top" src={imageMap[card.img]} />
                                                <Card.Body className='cardBodyDirectors text-center'>
                                                    <Card.Title>{card.title}</Card.Title>
                                                    <Card.Text>{card.text}</Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    ))}
                                </div>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div> */}
            </section>

        </div>
    )
}

export default Directors
