import React from 'react'
import Contactsection from './Home/Contactsection'
import contctbg from './Images/RestImages/contactUs.jpg'

const Contact = () => {
    // const sendEmail = (e) => {
    //     e.preventDefault();
    
    //     emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_USER_ID')
    //       .then((result) => {
    //         console.log(result.text);
    //         alert("Message sent successfully!");
    //       }, (error) => {
    //         console.log(error.text);
    //         alert("Failed to send message.");
    //       });
    //   };



    return (
        <div  style={{
            backgroundImage: `url(${contctbg})`,
            backgroundSize: 'cover',
            backgroundAttachment: 'fixed',
        }}>
<section 
style={{
    height: '15vh',
    background: 'black'
}}
>

</section>
        
        <section className='pb-5'
       
        >
            <h2 className='text-center text-dark p-5'>Contact Us</h2>
            <div className='container'>
                <div className='row'>
                    <div 
                     style={{
                        background: 'rgba(128, 0, 128, 0.5)',
                        borderRadius: '5px'
                    }}
                    className='col-lg-5 col-md-6 col-sm-12 d-flex justify-content-center align-items-center text-light'>
                        <div className='p-3'>
                            <h3 className='text-center'>Get in Touch</h3>
                            <p>Questions, comments, or suggestions? Simply fill in the form and we’ll be in touch shortly.</p>
                            <p><i className="bi bi-telephone p-2"></i>+92 311 6741249</p>
                            <p><i className="bi bi-envelope p-2"></i>Info@infinitealgos.com</p>
                            <p><i className="bi bi-geo-alt p-2"></i>T-8, 3rd Floor, Arshad Sharif Plaza, Plot-16, <br /><span className='p-4'>G-11 Markaz, 44000 Islamabad, Pakistan</span></p>
                        </div>
                    </div>
                    <div className='p-3 col-lg-7 col-md-6 col-sm-12
                    d-flex justify-content-center align-items-center
                    '>
                        <div className='col-md-10'>
                            <input type='text'
                                className='form-control mb-3'
                                placeholder='Enter Your Name'
                            />
                            <input type='text'
                                className='form-control mb-3'
                                placeholder='Enter Your Phone'
                            />
                            <input type='email'
                                className='form-control mb-3'
                                placeholder='Enter Your Email'
                            /><textarea type='text'
                                className='form-control mb-3'
                                placeholder='Enter Your Message'
                                rows={5}
                            />
                            <button className='btn btn-dark form-control'>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </div>
    )
}

export default Contact
