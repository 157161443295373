import React from 'react'
import { useState } from 'react'
import milestonesbgImg from '../Images/RestImages/milestonesbgImg.jpg'
import AnimatedNumbers from 'react-animated-numbers';
import './Milestones.css'

// 
const Milestones = () => {
    const [numOfProjects, setNumOfProjects] = useState(100)
    const [happyCustomers, setHappyCustomers] = useState(100)
    const [customerSatisfaction, setCustomerSatisfaction] = useState(95)
    const [yearsOFExperience, setYearsOfExperience] = useState(5)

    return (
        <section className='p-3 text-light milestoneBgSection'
        style={{
            backgroundImage: `url(${milestonesbgImg})`,
          
        }}
        >
            <h2 className='text-center'>Milestones</h2>
            <p className='text-center text-light'>What we have achieved so far</p>
            <div className='container '>
                <div className='row'>
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                        <div className='d-flex justify-content-center aos-init aos-animate'
                            data-aos='fade-up' data-aos-delay='400'
                        >
                            <h3 className='text-light'>
                                <AnimatedNumbers
                                    animateToNumber={`${numOfProjects}`}
                                    fontStyle={{ fontSize: '3rem', color: '#ffffff' }}
                                    config={{ tension: 50, friction: 10 }}
                                />
                            </h3>
                            <h3 className='percent-symbol p-1'
                                style={{ fontSize: '3rem', color: '#ffffff' }}
                            >+</h3>
                        </div>
                        <p className='text-center'>Projects Completed</p>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                        <div className='d-flex justify-content-center aos-init aos-animate'
                            data-aos='fade-up' data-aos-delay='400'
                        >
                            <h3>
                                <AnimatedNumbers
                                    animateToNumber={happyCustomers}
                                    fontStyle={{ fontSize: '3rem', color: '#ffffff' }}
                                    config={{ tension: 50, friction: 20 }}
                                />
                            </h3>
                            <h3 className='percent-symbol p-1'
                                style={{ fontSize: '3rem', color: '#ffffff' }}
                            >+</h3>
                        </div>
                        <p className='text-center'>Happy Customers</p>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                        <div className='d-flex justify-content-center aos-init aos-animate'
                            data-aos='fade-up' data-aos-delay='400'
                        >
                            <h3>
                                <AnimatedNumbers
                                    animateToNumber={customerSatisfaction}
                                    fontStyle={{ fontSize: '3rem', color: '#ffffff' }}
                                    config={{ tension: 200, friction: 20 }}
                                />
                            </h3>
                            <h3 className='percent-symbol p-1'
                                style={{ fontSize: '3rem', color: '#ffffff' }}
                            >%</h3>
                        </div>

                        <p className='text-center'>Customer Statisfaction</p>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                        <div className='d-flex justify-content-center aos-init aos-animate'
                            data-aos='fade-up' data-aos-delay='400'
                        >
                            <h3>
                                <AnimatedNumbers
                                    animateToNumber={yearsOFExperience}
                                    fontStyle={{ fontSize: '3rem', color: '#ffffff' }}
                                    config={{ tension: 200, friction: 20 }}
                                />

                            </h3>
                        </div>

                        <p className='text-center'>Years OF Experience</p>
                    </div>

                </div>
            </div>
        </section >
    )
}

export default Milestones
