import React from 'react'
import { Link } from 'react-router-dom'
import '../Footer/Footerbar.css'
import logo from '../Footer/footerLogo.jpeg'
const Footerbar = () => {
    return (
        <div className='container-fluid footerDiv'>
            <div className='row'>
                <div className='col-md-3 text-center text-light'>
                    <img className='footerLogoImage img img-thumbnail' src={logo} alt='footer-logo' />
                    <h4 className='mb-4'>Infinite Algos</h4>
                    <p className='text-center text-light'>Join us to revolutionalize
                        the digital landscape</p>

                </div>
                <div className='col-md-3 text-light'>
                    <h4 className='text-center mt-4 mb-2'>Services</h4>
                    <div className='d-flex align-items-center justify-content-center'>
                        <div>
                            <p className='footerServices'><Link className='text-light text-decoration-none' to={'/services'}>Web Development</Link></p>
                            <p className='footerServices'><Link className='text-light text-decoration-none' to={'/services'}>Mobile Development</Link></p>
                            <p className='footerServices'><Link className='text-light text-decoration-none' to={'/services'}>AI Solutions</Link></p>
                            <p className='footerServices'><Link className='text-light text-decoration-none' to={'/services'}>Ui Ux Design</Link></p>
                            <p className='footerServices'><Link className='text-light text-decoration-none' to={'/services'}>Graphics Designing</Link></p>
                        </div>
                    </div>
                </div>
                <div className='col-md-2 text-light text-center'>
                    <h4 className='mt-4 mb-2'>Nav Links</h4>
                    <div className='footerNavLinks'>
                        <div className='mb-1'><Link className='text-decoration-none text-light navLinks' to='/home'>Home</Link></div>
                        <div className='mb-1'><Link className='text-decoration-none text-light navLinks' to='/services'>Services</Link> </div>
                        <div className='mb-1'><Link className='text-decoration-none text-light navLinks' to='/about'>About</Link></div>
                        <div className='mb-1'><Link className='text-decoration-none text-light navLinks' to='/contact'>Contact</Link> </div>
                        {/* <div className='mb-1'><Link className='text-decoration-none text-light navLinks' to='/hirring'>Hirring</Link></div> */}
                        {/* <div className='mb-1'><Link className='text-decoration-none text-light navLinks' to='/team'>Team</Link></div> */}
                        {/* <div className='mb-1'><Link className='text-decoration-none text-light navLinks' to='/directors'>Directors</Link> </div> */}
                        {/* <div className='d-flex gap-3 mt-4'>
                        <h4><i className="bi bi-facebook"></i></h4>
                        <h4><i className='bi bi-whatsapp'></i></h4>
                        <h4><i className='bi bi-instagram'></i></h4>
                        <h4><i class="bi bi-linkedin"></i></h4>
                    </div> */}
                        <div className='d-flex gap-3 mt-4 justify-content-center'>
                            <a style={{
                                color: 'white',
                                fontSize: '25px'
                            }} href="https://wa.me/923116741249" target="_blank" rel="noopener noreferrer" className="me-3">
                                <i className="bi bi-whatsapp"></i>
                            </a>
                            <a style={{
                                color: 'white',
                                fontSize: '25px'
                            }} href="https://www.instagram.com/infinitealgos9/" target="_blank" rel="noopener noreferrer" className="me-3">
                                <i className="bi bi-instagram"></i>
                            </a>
                            <a style={{
                                color: 'white',
                                fontSize: '25px'
                            }} href="https://www.linkedin.com/company/infinite-algos/" target="_blank" rel="noopener noreferrer">
                                <i className="bi bi-linkedin"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='col-md-4 text-light text-center'>
                    <h4 className='mt-4 mb-2'>Contact Us</h4>
                    <div className='d-flex justify-content-center align-items-center'>
                        <div>
                            <p className='text-center'><i className="bi bi-telephone p-2"></i>+92 311 6741249</p>
                            <p className='text-center'><i className="bi bi-envelope p-2"></i>Info@infinitealgos.com</p>
                            <p className='text-center'><i className="bi bi-geo-alt p-2"></i>T-8, 3rd Floor, Arshad Sharif Plaza, Plot-16,<span className='p-4'>G-11 Markaz, 44000 Islamabad, Pakistan</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footerbar
